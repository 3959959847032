import React from "react"
import GeneralPest from "../../home/generalPest"
import { RichText } from "prismic-reactjs"
import stone from "../../../assets/layout/stone.svg"
import Video from "../../home/whoAreWe/video"
import "./style.scss"

export default function transportControl({
  data,
  offerIncludes,
  offerFeatures,
}) {
  return (
    <div className="transport-pest">
      <div className="container">
        <div className="transport-general-pest">
          <GeneralPest
            offerIncludes={offerIncludes}
            offerFeatures={offerFeatures}
          />
        </div>
        <div className="transport-pest-content">
          <RichText render={data.primary.title} />
          <img src={stone} alt="stone" className="transport-pest-stone" />
          <RichText render={data.primary.description} />
        </div>
        {/* <Video
          videoSrcURL="https://www.youtube.com/embed/6OM3zoAJ3lU"
          videoTitle="Official Music Video on YouTube"
        /> */}
        <Video
          videoSrcURL="https://www.youtube.com/watch?v=6OM3zoAJ3lU"
          videoCode="6OM3zoAJ3lU"
          videoTitle="Official Music Video on YouTube"
        />
      </div>
    </div>
  )
}
