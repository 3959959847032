import React from "react"
import Layout from "../components/layout"
import HeroType1 from "../components/home/hero"
import HeroType2 from "../components/termiteInspection/hero"
import TransportPest from "../components/transport/transportControl"
import TransportServices from "../components/transport/transportServices"
import TransportNeed from "../components/transport/transportNeed"
import ChoosePest from "../components/info/choosePest"
import Order from "../components/home/order"
// import BlogPost from "../components/termiteInspection/blogPosts"
import BlogPost from "../components/home/blog"
import Call from "../components/home/call"
import divider1 from "../assets/home/divider1.png"
import separator2 from "../assets/info/separator2.png"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Transport = props => {
  React.useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }, [])

  if (!props.data) return null
  const data = props.data.allPrismicTransport.edges[0].node.dataRaw
  const id = props.data.allPrismicTransport.edges[0].node.uid
  // data sources
  const call = props.data.allPrismicHomeCall.edges[0].node.data
  const blogs = props.data.allPrismicBlogSection.edges
  const hero = props.data.allPrismicUniversalBlock1.edges[0].node.data
  const control = data.body[0]
  const services = data.body[1]
  const need = data.body[2]
  const choose = data.body[3]
  const order = props.data.allPrismicHomeOrder.edges[0].node.data
  const heroSection = {
    src: hero.home_page_hero_banner,
  }
  const title = data.title[0].text
  const description = data.metdescription[0].text

  const bannerImage1 = Object.keys(data.banner_image_1).length
    ? data.banner_image_1
    : null

  const offerIncludes = Object.keys(data.offer_widget_includes).length
    ? data.offer_widget_includes
    : null

  const offerFeatures = Object.keys(data.banner_offer_features).length
    ? data.banner_offer_features
    : null

  return (
    <div>
      <Helmet>
        <meta name="description" content={description} />
        <title>{title}</title>
      </Helmet>
      <Layout>
        {bannerImage1 ? (
          <HeroType2
            data={data}
            bannerImage1={bannerImage1}
            bannerImage2={null}
            offerIncludes={offerIncludes}
            offerFeatures={offerFeatures}
          />
        ) : (
          <HeroType1
            data={heroSection}
            bannerImage1={null}
            bannerImage2={null}
            offerIncludes={offerIncludes}
            offerFeatures={offerFeatures}
          />
        )}
        <img src={divider1} style={{ width: "100%" }} alt="" />
        <TransportPest
          data={control}
          offerIncludes={offerIncludes}
          offerFeatures={offerFeatures}
        />
        <TransportServices data={services} id={id} />
        <TransportNeed data={need} />
        <img
          src={separator2}
          style={{ width: "100%", backgroundColor: "#dde4ec" }}
          alt=""
        />

        <ChoosePest data={choose} id={id} />
        <Order data={order} />
        <BlogPost data={blogs} />
        <Call data={call} />
      </Layout>
    </div>
  )
}

export const query3 = graphql`
  query MyQuery4($id: String!) {
    allPrismicTransport(filter: { id: { eq: $id } }) {
      edges {
        node {
          uid
          id
          dataRaw
        }
      }
    }
    allPrismicHomeCall {
      edges {
        node {
          data {
            image {
              url
            }
            bg_image {
              url
            }
            paragraph {
              raw
            }
            title {
              raw
            }
          }
        }
      }
    }
    allPrismicBlogSection(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            content {
              raw
              text
            }
            image {
              url
            }
            title {
              raw
            }
          }
          dataRaw
        }
      }
    }
    allPrismicUniversalBlock1 {
      edges {
        node {
          data {
            home_page_hero_banner {
              url
            }
          }
        }
      }
    }
    allPrismicHomeOrder {
      edges {
        node {
          data {
            content {
              raw
            }
            bg_image {
              url
            }
            title {
              raw
            }
          }
        }
      }
    }
  }
`
export default Transport
